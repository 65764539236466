








import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';

@Component
export default class PermissionDenyComponent extends BaseComponent {
    refreshClick() {
        window.location.reload();
    }
}
